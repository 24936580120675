@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    text-decoration: none;
    line-height: 1;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #000000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin: 0;
    padding: 0;
}

.mobile, #mobile {
    display: none;
}

:root {
    --mainBlack: #000000;
    --mainWhite: #ffffff;
    --subBlack: #1b1b1b;
    --bgGrey: #ECF0F0;
    --bgDark: #2C2C2C;
}

.sec-services {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.hero-services {
    width: 100%;
    height: 320px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ser-company {
    background-image: url(../../img/Jakarta01.jpg);
    background-size: cover;
}

.ser-visa {
    background-image: url(../../img/sbg-visa.jpg);
    background-size: cover;
}

.ser-investment {
    background-image: url(../../img/sbg-invest.jpg);
    background-size: cover;
}

.ser-tax {
    background-image: url(../../img/sbg-tax.jpg);
    background-size: cover;
}

.ser-ma {
    background-image: url(../../img/sbg-ma.jpg);
    background-size: cover;
}


.hero-services-bg {
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    width: 100%;
    height: 100%;
}

.hero-services-cont {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 0 40px;
    z-index: 1;
    flex-direction: column;
}

.services-title {
    font-size: 40px;
    color: var(--mainWhite);
    font-weight: 300;
    line-height: 1.3;
}

.div-services {
    width: 100%;
    max-width: 1440px;
    padding: 0 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: start;
}

.services-title-box {
    gap: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 56px;
}

.service-desc-div {
    width: 100%;
}

.service-desc {
    font-size: 18px;
    font-weight: 300;
    line-height: 175%;
    margin-top: 12px;
}

.title-box-services {
    margin-top: 32px;
    gap: 6px;
    width: 100%;
}

.company-types {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 40px;
    column-gap: 12px;
    margin-bottom: 60px;
}

.company-type {
    background-color: var(--bgGrey);
    padding: 24px 32px;
}

.c-type-title {
    line-height: 1.5;
    font-weight: 500;
    font-size: 18px;
    min-height: 52px;
    margin-top: 8px;
}

.c-type-desc {
    font-size: 14px;
    line-height: 1.75;
    margin-top: 16px;
}

.companyre-comp {
    display: grid;
    grid-template-columns: 0.8fr 1fr 1fr 1fr;
    gap: 12px;
    font-size: 14px;
    margin: 80px 0 80px 0;
}

.companyre-con {
    font-size: 14px;
    font-weight: 500;
    width: 25%;
    padding: 20px 40px;
    text-align:left;
}

.companytype-title {
    font-weight: 500;
    font-size: 18px;
}

.company-table {
    gap: 12px;
    font-size: 14px;
    margin: 40px 0 80px 0;
}

th, td {
    border-bottom: 1px solid rgba(0,0,0,0.15);
    border-left: 1px solid rgba(0,0,0,0.15);
}

.noborder-l {
    border-left: none;
}

.noborder-b {
    border-bottom: none;
}

td {
    padding: 0 !important;
}

/*.companytype-title, .companytype-nature, .companytype-structure, .companytype-capital {*/
/*    background-color: var(--bgGrey);*/
/*    padding: 20px;*/
/*    height: 100%;*/
/*}*/

.companyre-li {
    line-height: 1.75;
}

/* Start of Visa */

.visa-types-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    text-align: center;
    margin: 40px 0 120px 0;
    position: relative;
}

.visa-types-tags {
    display: flex;
    gap: 6px;
}

.visa-types-tag {
    font-size: 10px;
    font-weight: 700;
    /*margin-bottom: 10px;*/
    background-color: var(--mainBlack);
    color: var(--mainWhite);
    padding: 6px 12px;
    display: inline-block;
}

.visa-types-grid-p {
    line-height: 1.5;
    padding: 16px 0;
    background-color: var(--bgGrey);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    /*border: 1px solid var(--mainBlack);*/
}


/* End of Visa */

/* Start of Tax */

.tax-desc-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 40px 0 120px 0;
    gap: 20px;
}

.tax-desc {
    padding: 24px 20px;
    background-color: var(--bgGrey);
}

.tax-ser-title {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
}

.tax-desc-div {
    display: flex;
    align-items: stretch;
    margin-top: 20px;
}

.tax-line {
    width: 1px;
    border: 0.5px solid var(--mainBlack);
    background-color: var(--mainBlack);
    margin-right: 12px;
}

.tax-ser-desc {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 300;
}

/* End of Tax */

/* Start of MA */

.ma-guide-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}

.ma-guide {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: stretch;
    justify-content: center;
}

.ma-guide-box {
    padding: 32px;
    background-color: var(--bgGrey);
    width: 33%;
}

.ma-guide-title {
    font-size: 18px;
    margin-bottom: 12px;
    font-weight: 500;
}

.ma-guide-desc {
    line-height: 1.5;
    font-size: 14px;
}

.ma-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0 120px 0;
}

.ma-40 {
    margin-bottom: 40px;
}

.ma-laws {
    padding: 20px 18px;
    line-height: 1.75;
    max-width: 1
}

.ma-box-line {
    background-color: rgba(0,0,0,0.15);
    width: 100%;
    height: 1px;
}

/* End of MA */

/* Start of Investment */

.investment-box {
    display: grid;
    width: 100%;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 40px 0 120px 0;
}

.investment-grids {
    background-color: var(--bgGrey);
    padding: 32px;
}

.investment-grid-title {
    font-size: 18px;
    font-weight: 500;
    margin: 8px 0 12px 0;
}

.investment-grid-desc {
    font-size: 14px;
    line-height: 1.75;
}

/* End of Investment */