@media only screen and (max-width: 768px) {

    :root {
        --mainBlack: #000000;
        --mainWhite: #ffffff;
        --subBlack: #1b1b1b;
        --bgGrey: #ECF0F0;
        --bgDark: #2C2C2C;
    }

    .mobile, #mobile {
        display: flex !important;
    }

    .desktop {
        display: none !important;
    }

    h1 {
        font-size: 20px;
        text-align: center;
    }

    h2 {
        font-size: 20px;
        font-weight: 400;
    }

    /* Start of Navbar */

    .sec-nav {
        display: none;
    }

    .sec-mobnav {
        display: flex;
        width: 100vw;
        z-index: 99999;
        height: 56px;
        background-color: var(--mainBlack);
        position: fixed;
        top: 0;
        align-items: center;
        justify-content: center;
    }

    .div-mobnav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
    }

    .navlogo {
        height: 20px;
    }

    .div-mobSub {
        width: 100vw;
        height: 100vh;
        background-color: var(--mainBlack);
        z-index: 999;
        position: fixed;
    }

    .mobSubs {
        display: flex;
        color: var(--mainWhite);
        width: 100%;
        height: 100%;
        flex-direction: column;
        gap: 32px;
        padding: 80px 20px;
    }

    .mobSub {
        color: white;
        font-size: 16px;
        font-weight: 700;
    }

    .mobnav-sub {
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-left: 18px;
    }

    .mobnav-subs {
        color: var(--mainWhite);
        font-size: 16px;
    }

    .moblangs {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 32px;
    }

    .moblang {
        font-size: 16px;
        color: var(--mainWhite);
    }

    .moblang-div {
        height: 13px;
        width: 1px;
        background-color: white;
    }

    .nav-lang-drop {
        display: flex;
    }

    /* End of Navbar */
    /* Start of Hero */

    .div-hero {
        padding: 20px;
        margin-top: -40px;
    }

    .hero-logo {
        width: 100%;
        max-width: 70%;
    }

    /* End of Hero */
    /* Start of About */

    .div-about {
        padding: 20px;
    }

    .about-left {
        width: 100%;
    }

    .about-txt {
        font-size: 16px;
    }

    .about-right {
        display: none;
    }

    /* End of About */

    /* Start of Valorem */

    .div-valorem-home {
        flex-direction: column;
        padding: 120px 24px;
    }

    .title-box-val {
        margin-bottom: 60px;
    }

    .div-val-left, .div-val-right {
        width: 100%;
    }

    .val-scroll {
        display: none;
    }

    /* End of Valorem */

    /* Start of Services Home */

    .div-services-home {
        padding: 0 20px;
        gap: 20px;
        margin: 40px 0 160px 0;
    }

    .home-serv-grid {
        grid-template-columns: 1fr;
    }

    .services-title-box {
        padding-left: 0;
    }

    .home-serv-grids {
        border: 1px solid var(--mainWhite);
    }

    .ser-learnmore {
        font-weight: 500;
    }

    /* End of Services Home */

    /* Start of Services */

    .sec-services {
        overflow: hidden;
    }

    .sec-tab {
        overflow: scroll;
    }

    .s-tabs {
        min-width: 140px;
        padding: 12px 0 !important;
    }

    .div-tab {
        padding: 0 24px;
    }

    .tab-title {
        line-height: 1.5;
    }

    .hero-services {
        height: 180px !important;
    }

    .hero-services-cont {
        padding: 0 20px !important;
    }

    .services-title {
        font-size: 22px !important;
    }

    .div-services {
        padding: 0 20px !important;
    }

    .service-desc {
        font-size: 16px !important;
        margin: 0 !important;
    }

    .company-types {
        grid-template-columns: 1fr !important;
        gap: 12px !important;
    }

    .table-hor {
        overflow-x: scroll;
        width: 100%;
    }

    .company-table {
        width: 1280px;
    }

    .noborder-l {
        width: 10% !important;
    }

    .companytype-title {
        padding-inline-start: 20px !important;
    }

    .companytype-nature, .companytype-structure, .companytype-capital {
        padding-right: 12px !important;
    }
    .padding-inline {
        padding-inline-start: 20px !important;
    }

    .company-title-height {
        line-height: 1.5 !important;
    }

    .visa-types-grid {
        grid-template-columns: 1fr !important;
    }

    .investment-box {
        grid-template-columns: 1fr !important;
    }

    .investment-grids {
        padding: 22px !important;
    }

    .investment-grid-title {
        margin: 12px 0 12px 0 !important;
    }

    .ma-guide-div {
        padding: 40px 0 0 0 !important;
    }

    .ma-40 {
        margin-bottom: 20px !important;
    }

    .ma-guide {
        grid-template-columns: 1fr !important;
        flex-direction: column !important;
    }

    .ma-guide-box {
        width: 100% !important;
        padding: 22px !important;
    }

    .ma-laws {
        padding: 18px 0px !important;
        line-height: 1.5 !important;
        font-size: 14px !important;
    }


    /* End of Services */

    /* Start of Service Tab */

    .sec-tab {
        margin-bottom: 20px !important;
    }

    /* End of Service Tab */

    /* Start of Values */

    .div-value {
        width: 100%;
        padding: 120px 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .m-20 {
        margin-bottom: 40px !important;
    }

    .value-img-mob {
        width: 100%;
        margin-top: 80px;
    }

    .div-value-mob {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        width: 100%;
    }

    /*.value-content-box {*/
    /*    padding: 0 20px;*/
    /*}*/

    .div-value-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin-bottom: 12px;
    }

    .value-title {
        font-size: 20px;
    }

    .value-ic {
        width: 24px;
        margin-top: 2px;
    }

    .value-ti {
        font-size: 22px;
        padding-left: 4px;
    }

    .value-desc {
        font-size: 16px;
        line-height: 150%;
    }

    .value-line {
        width: 100%;
        height: 1px;
        background-color: black;
        margin: 32px 0;
    }

    /* End of Values */
    /* Start of History */

    .div-history.dpf-ccc {
        padding: 80px 20px;
    }

    .title-box.dpf-rcc.value-box {
        align-items: center;
        justify-content: start;
        margin-bottom: 20px;
    }

    .history-mob {
        width: 100%;
    }

    /* End of History */
    /* Start of Location */

    .div-location.dpf-rcc {
        padding: 120px 0;
        gap: 12px;
        flex-direction: column;
        width: 100%;
    }

    .mapouter {
        position: relative;
        text-align: right;
        height: 420px;
        width: 100%;
    }

    .gmap_canvas {
        overflow: hidden;
        background: none!important;
        height: 420px;
        width: 100%;
    }

    .location-box {
        background-color: var(--subBlack);
        padding: 40px 32px;
        color: var(--mainWhite);
        max-width: none;
        height: auto;
    }

    .location-subt {
        font-size: 20px;
        font-weight: 700;
    }

    .location-add {
        line-height: 175%;
        font-size: 14px;
        margin-top: 20px;
    }

    .div-mail {
        margin-top: 80px;
    }

    /* End of Location */
    /* Start of Footer */

    .footer-menus.dpf-rcc {
        flex-direction: column;
        gap: 20px;
    }

    .footer-division {
        width: 20px;
        height: 1px;
    }

    /* End of Footer */


}