@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  text-decoration: none;
  line-height: 1;
  scroll-behavior: smooth;
}

/*html {*/
/*  scroll-behavior: smooth;*/
/*}*/

body {
  color: #000000;
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin: 0;
  padding: 0;
}

.mobile, #mobile {
  display: none;
}

.div-1440 {
  width: 100%;
  padding: 120px 40px;
  max-width: 1440px;
}

.div-70pc {
  width: 70%;
  padding: 120px 40px;
  max-width: 1440px;
}

.dp-fwfh {
  width: 100vw;
  height: 100vh;
}

.dpf-ccc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dpf-ccs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.dpf-cse {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: end;
}

.dpf-css {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.dpf-csb {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

.dpf-csc {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.dpf-ces {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.dpf-cec {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: start;
}

.dpf-rcc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.dpf-rsc {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}

.dpf-rcs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.dpf-rce {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.dpf-css {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
}

h1 {
  color: var(--mainWhite);
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  padding: 0;
  line-height: 1.3;
}

h2 {
  font-size: 32px;
  font-weight: 200;
  margin: 0;
}

p {
  margin: 0;
}

/* Color */
:root {
  --mainBlack: #000000;
  --mainWhite: #ffffff;
  --subBlack: #1b1b1b;
}

/* Start of Navbar */

.sec-nav {
  width: 100vw;
  height: 56px;
  background-color: var(--mainBlack);
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec-mobnav {
  display: none;
}

.div-nav {
  width: 100%;
  max-width: 1440px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navlogo {
  height: 28px;
}

.nav-menus {
  display: flex;
  gap: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-menu {
  color: rgba(255,255,255,0.8);
  font-size: 15px;
}

.nav-menu:hover {
  color: var(--mainWhite);
}

.nav-serv-box {
  padding-top: 40px;
  height: 100%;
}

.nav-serv-drop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-serv-drops {
  position: absolute;
  min-width: 230px;
  padding: 12px;
  background-color: var(--bgDark);
  border: 1px solid rgba(255,255,255,0.3);
  display: none;
  left: 0;
}

.nav-serv-drop:hover .nav-serv-drops  {
  display: block;
}

.dropdown-line {
  width: 100%;
  height: 1px;
  background-color: rgba(255,255,255,0.3);
  margin: 16px 0;
}

.nav-s-drops {
  font-size: 14px;
  color: rgba(255,255,255,0.7);
}

.nav-s-drops:hover {
  color: var(--mainWhite);
}

.nav-lang {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.nav-lang:hover .nav-lang-drop {
  display: block;
}

.globe-div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lang-globe {
  width: 21px;
  height: 21px;
}

.nav-lang-box {
  padding-top: 44px;
  height: 100%;
}

.nav-lang-drop {
  background-color: var(--bgDark);
  padding: 16px;
  border: 1px solid rgba(255,255,255,0.3);
  min-width: 80px;
  position: absolute;
  left: 0;
  display: none;
}

.lang-icon {
  height: 14px;
}

.langs {
  color: rgba(255,255,255,0.7);
  font-size: 14px;
}

.lang-icons:hover .langs{
  color: var(--mainWhite);
}

.lang-icons {
  gap: 10px;
  display: flex;
  align-items: center;
}

.lang-div {
  height: 1px;
  width: 100%;
  margin: 16px 0;
  background-color: rgba(255,255,255,0.3);
}

/* End of Navbar */

/* Start of Hero */

.sec-hero {
  width: 100vw;
  height: 100vh;
  background: url('img/hero-bg.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.sec-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  z-index: 1;
}

.div-hero {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.hero-logo {
  width: 400px;
}

.hero-line {
  width: 200px;
  height: 1px;
  background-color: var(--mainWhite);
  margin: 20px 0;
}

/* End of Hero */

/* Start of About */

.sec-about {
  background-color: var(--subBlack);
  color: var(--mainWhite);
}

.div-about {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  gap: 40px;
  padding: 40px;
}

.about-left, .about-right {
  width: 40%;
  height: 100%;
  max-height: 500px;
}

.about-left.ko {
  height: auto;
  gap: 40px;
}

.title-box {
  margin: 0 0 20px 0;
  gap: 6px;
  width: 100%;
}

.side-rec {
  width: 14px;
}

.about-txt {
  line-height: 175%;
  font-size: 18px;
  font-weight: 300;
}

.about-div-img {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.about-img {
  max-width: 500px;
  height: 100%;
  object-fit: cover;
  animation: img-rotation 300s infinite linear;
}

@keyframes img-rotation {
  from {
    transform: rotate(359deg) scale(3);
  }
  to {
    transform: rotate(0deg) scale(1);
  }
}

.about-left-bot {
  width: 100%;
}

.about-line {
  width: 100%;
  height: 0.5px;
  background-color: var(--mainWhite);
  margin-bottom: 20px;
  margin-top: 40px;
}

.about-val {
  text-align: right;
  font-weight: 200;
}


/* End of About */

/* Start of Valorem */

.sec-valorem-home {
  width: 100%;
  background-color: var(--subBlack);
  color: var(--mainWhite);
}

.div-valorem-home {
  width: 100%;
  max-width: 1080px;
  padding: 180px 32px;
  /*border: 1px white solid;*/
}

.title-box-val {
  margin: 0 0 20px 0;
  gap: 12px;
  width: 100%;
  padding-top: 6px;
}

.title-h2.val0 {
  font-size: 40px;
}

.title-h2.val {
  font-weight: 700;
  font-size: 40px;
}

.div-val-left {
  width: 40%;
}

.div-val-right {
  width: 60%;
}

.valorem-txt {
  font-size: 16px;
  font-weight: 200;
  line-height: 175%;
}

.valorem-txt.ko {
  font-size: 18px;
}

.val-scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 120px;
}

.line-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid rgba(255,255,255,0.8);
}

.mouse-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 16px;
  padding-top: 20px;
}

.mouse-border {
  border: 1px solid rgba(255,255,255,0.8);
  width: 18px;
  height: 30px;
  border-radius: 20px;
  position: relative;
}


.mouse-circle {
  width: 4px;
  height: 4px;
  background-color: rgba(255,255,255,0.8);
  border-radius: 500px;
  position: absolute;
  left: 6px;
  top: 6px;
}


.mouse-line {
  width: 1px;
  height: 140px;
  background: linear-gradient(to bottom, rgba(255,255,255,0),rgba(255,255,255,0.8));
}

.mouse-scroll-txt {
  font-size: 10px;
  line-height: 130%;
  margin-top: 6px;
  text-align: center;
  color: rgba(255,255,255,0.8)
}

/* End of Valorem */

/* Start of Services */

.sec-services-home {
  background-color: var(--subBlack);
  color: var(--mainWhite);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.div-services-home {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  gap: 40px;
  padding: 40px;
  margin: 80px 0 200px 0;
}

.services-title-box {
  margin: 0 0 20px 0;
  padding-left: 24px;
  gap: 6px;
  width: 100%;
}

.home-serv-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.home-serv-grids {
  cursor: pointer;
  padding: 24px;
  color: white;
  border: 1px solid var(--subBlack);
  transition: border 220ms ease-in-out;
}

.home-serv-grids:hover {
  border: 1px solid var(--mainWhite);
}

.home-serv-grids:hover > .ser-learnmore {
  font-weight: 500;
}

.home-serv-title {
  font-size: 18px;
  margin-bottom: 18px;
  line-height: 1.3;
}

.home-serv-title.ko {
  font-size: 20px;
  margin-bottom: 18px;
  line-height: 1.3;
}

.home-serv-desc {
  line-height: 1.5;
  font-weight: 200;
  font-size: 16px;
}

.home-serv-desc.ko {
  line-height: 1.75;
  font-weight: 200;
  font-size: 18px;
}

.ser-learnmore {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 13px;
  font-weight: 200;
  margin-top: 24px;
  transition: font-weight 220ms ease-in-out;
}

.service-arrow {
  height: 8px;
  margin-left: 6px;
}

/* End of Services */

/* Start of Biz */

.sec-biz {
  min-height: 800px;
}

.title-box-biz {
  margin: 0 0 80px 0;
  gap: 6px;
  width: 100%;
  max-width: 442px;
}

.biz-box {
  width: 100%;
  gap: 80px;
}

.biz-left {
  width: 35%;
  height: 100%;
}

.biz-title-box {
  padding: 18px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.biz-titles {
  font-size: 22px;
}

.biz-acc-box {
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.biz-acc-u, .biz-acc-d {
  position: absolute;
  background-color: var(--mainBlack);
}

.biz-acc-u {
  height: 14px;
  width: 2px;
}

.biz-acc-d {
  height: 2px;
  width: 14px;
}

.biz-desc-box {
  width: 100%;
  overflow: hidden;

}

.biz-desc {
  font-size: 16px;
  line-height: 130%;
  font-weight: 300;
}

.biz-right {
  width: 35%;
  height: 100%;
}

.biz-right-box {
  border: 1px solid var(--mainBlack);
  padding: 32px;
}

.biz-listing {
  list-style-position: outside;
  margin: 0;
  gap: 12px;
}

.biz-list {
  line-height: 150%;
  margin-left: -12px;
  font-size: 16px;
}

.biz-subt {
  margin-bottom: 14px;
  font-weight: 600;
  font-size: 18px;
}

.subt-sec {
  margin-top: 32px;
}

/* End of Biz */

/* Start of Legal */


.sec-legal {
  min-height: 950px;
  background-color: var(--subBlack);
  color: white;
}

.legal-right-box {
  border: 1px solid var(--mainWhite);
  padding: 32px;
  background-color: var(--subBlack);
  position: relative;
  z-index: 1;
}

.v-icon {
  position: absolute;
  opacity: 0.07;
  left: 100px;
  top: 100px;
}

.legal-acc-u, .legal-acc-d {
  position: absolute;
  background-color: var(--mainWhite);
}

.legal-acc-u {
  height: 14px;
  width: 2px;
}

.legal-acc-d {
  height: 2px;
  width: 14px;
}

.biz-div-mob {
  display: none;
}

/* End of Legal */

/* Start of Value */

.sec-value {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-value {
  width: 100%;
  max-width: 1200px;
  padding: 200px 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.value-title-box, .value-content-box {
  width: 100%;
  position: relative;
}

.value-content-box {
  display: flex;
  align-items: center;
  justify-content: end;
}

.value-title {
  font-size: 44px;
  line-height: 130%;
  font-weight: 500;
}

.value-icon {
  position: absolute;
  top: 30px;
  left: -50px;
  z-index: -1;
  opacity: 0.05;
}

.value-img {
  width: 720px;
}

/* End of Value */

/* Start of History */

.sec-history {
  width: 100vw;
  background-image: url(./img/history-bg.png);
  background-size: cover;
}

.div-history {
  padding: 120px 40px;
  width: 100%;
  max-width: 1440px;
}

.history-img {
  width: 100%;
  max-width: 1100px;
}

.value-box {
  margin-bottom: 80px;
}

.t-white {
  color: white;
}

/* End of History */

/* Start of Location */

.sec-location {
  width: 100vw;
}

.div-location {
  padding: 120px 40px;
  gap: 12px;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 420px;
  width: 600px;}

.gmap_canvas {
  overflow: hidden;
  background: none!important;
  height: 420px;
  width: 600px;}

.location-box {
  background-color: var(--subBlack);
  padding: 40px 32px;
  color: var(--mainWhite);
  max-width: 360px;
  height: 420px;
}

.location-subt {
  font-size: 20px;
  font-weight: 700;
}

.location-add {
  line-height: 175%;
  font-size: 14px;
  margin-top: 20px;
  color: white;
}

.div-mail {
  display: flex;
  flex-direction: column;
}

.mail-p {
  line-height: 175%;
  font-size: 14px;
  margin-top: 20px !important;
  color: white;
}


/* End of Location */
/* Start of Footer */

.sec-footer {
  width: 100vw;
  background-color: var(--mainBlack);
  color: var(--mainWhite);
}

.div-footer {
  padding: 40px;
  width: 100%;
  max-width: 1280px;
}

.footer-top {
  width: 100%;
}

.footer-logo {
  width: 120px;
}

.footer-desc {
  font-size: 12px;
  margin-top: 12px;
}

.footer-menus {
  gap: 12px;
  margin-top: 40px;
}

.footer-menu {
  color:white;
  font-size: 14px;
}

.footer-division {
  height: 14px;
  width: 1px;
  background-color: var(--mainWhite);
}

.footer-bot {
  width: 100%;
}

.footer-d {
  width: 100%;
  height: 1px;
  background-color: var(--mainWhite);
  margin: 60px 0 20px 0;
}

.copyright {
  font-size: 12px;
}

/* End of Footer */