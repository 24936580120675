@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    text-decoration: none;
    line-height: 1;
}

html {
    scroll-behavior: smooth;
}

body {
    color: #000000;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin: 0;
    padding: 0;
}

.mobile, #mobile {
    display: none;
}

:root {
    --mainBlack: #000000;
    --mainWhite: #ffffff;
    --subBlack: #1b1b1b;
    --bgGrey: #ECF0F0;
    --bgDark: #2C2C2C;
}

.sec-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    background-color: var(--mainBlack);
    width: 100%;
}

.div-tab {
    width: 100%;
    gap: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    max-width: 1440px;
}

.s-tabs {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    line-height: 1.5;
    font-size: 12px;
    position: relative;
    color: var(--mainWhite);
    transition: font-weight 300ms ease-out;
    padding: 20px 0;
}

.s-tabs-bg {
    width: 90%;
    height: 2px;
    position: absolute;
    bottom: 8px;
    /*height: 100%;*/
    /*transform: skew(-20deg);*/
    /*border: 1px solid var(--mainWhite);*/
    /*z-index: 0;*/
    transition: background-color 240ms ease-out;
}

.s-tabs:hover > .s-tabs-bg {
    background-color: var(--mainWhite);
    /*border: none;*/
}

/*.s-tabs:hover {*/
/*    !*color: var(--mainBlack);*!*/
/*    font-weight: 500;*/
/*}*/

.tab-title {
    z-index: 1;
}

.tab-title.ko {
    font-size: 16px;
}
